import styled from 'styled-components'
import { media, borderHighlightColour } from '../../theme/'

const Stripe = styled.div`
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  z-index: 1;
  ${media.LargeScreenUp`
    transform: translateX(14vw);
    z-index: 1;
  `}
  ${media.GiantScreenUp`
      transform: translateX(11.1vw);
  `}
  background-color: ${borderHighlightColour};
`
export const StripeTop = Stripe.extend`
  ${media.LargeScreenUp`
    position: absolute;
    top: 0;
    height: 27px;
  `}
`
export const StripeBottom = Stripe.extend`
  display: none;
  ${media.LargeScreenUp`
    display: block;
    position: absolute;
    bottom: 0;
    top: initial;
    height: 240px;
  `}
`
export const StripeRelease = Stripe.extend`
  display: none;
  ${media.LargeScreenUp`
    transform: translateX(5vw);
  `}
  ${media.GiantScreenUp`
      transform: translateX(2.1vw);
  `}
  ${media.LargeScreenUp`
    display: block;
    position: absolute;
    bottom: 0;
    top: -64px;
    height: 180px;
  `}
`
export const StripeFilter = Stripe.extend`
  ${media.LargeScreenUp`
    position: absolute;
    top: 95px;
    height: 200px;
    z-index: 1 !important;
  `}
`
export const StripeHeading = Stripe.extend`
  display: none;
  ${media.LargeScreenUp`
    display: block;
    position: absolute;
    top: 34px;
    height: 42px;
  `}
`

export const StripeFull = Stripe.extend`
  display: none;
  ${media.LargeScreenUp`
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    height: auto;
    transform: translateX(9vw);
  `}
   ${media.GiantScreenUp`
      transform: translateX(6.1vw);
  `}
`

export const StripeFullWide = Stripe.extend`
  ${media.LargeScreenUp`
    display: block;
    position: absolute;
  `}

  top: 13.8em;
  bottom: 0;
  height: auto;
`

export const StripeFullWideNoHeading = Stripe.extend`
  ${media.LargeScreenUp`
    display: block;
    position: absolute;
  `}

  top: 6em;
  bottom: 0;
  height: 100%;
`
