import React from 'react'
import styled, { css } from 'styled-components'

const width = '28'
const height = '49'
const viewBox = '0 0 28 49'

const getDimensions = () => ({
  height,
  width
})

const getDimensionsCss = () => css`
  width: ${width}px;
  height: ${height}px;
`

const Image = styled.svg`
  ${({ noStyles }) => !noStyles ? getDimensionsCss() : null}
`

const defaultProps = {
  children: [
    <defs
      key='key-0'
    >
      <linearGradient
        id='s-0fea0ec66f-a'
        x1='50%'
        x2='50%'
        y1='0%'
        y2='98.309%'
      >
        <stop
          offset='0%'
          stopColor='#C3100D'
        />
        <stop
          offset='100%'
          stopColor='#BF1A18'
        />
      </linearGradient>
    </defs>,
    <path
      fill='url(#s-0fea0ec66f-a)'
      fillRule='evenodd'
      d='M107.508 31c-3.987 3-6.852 7.25-8.594 12.752-1.77 5.588-2.416 12.426-1.94 20.515L93 71.106l.554 8.224 9.149-4.528 1.479 2.957h6.283l1.479-2.957 8.502 4.528.554-8.224-4.158-6.839c.614-7.972.121-14.81-1.479-20.515-1.51-5.385-4.129-9.635-7.855-12.752z'
      transform='translate(-93 -31)'
      key='key-1'
    />
  ],
  viewBox
}

export default Object.assign(Image, {
  getDimensions,
  getDimensionsCss,
  defaultProps,
  displayName: 'LogoLittleRocket'
})
