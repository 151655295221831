import React from 'react'
import styled, { css } from 'styled-components'

const width = '13'
const height = '22'
const viewBox = '0 0 13 22'

const getDimensions = () => ({
  height,
  width
})

const getDimensionsCss = () => css`
  width: ${width}px;
  height: ${height}px;
`

const Image = styled.svg`
  ${({ noStyles }) => !noStyles ? getDimensionsCss() : null}
`

const defaultProps = {
  children: [
    <path
      fill='#1A1919'
      d='M3.208 7.333H0V11h3.208v11h5.348V11h3.894l.383-3.667H8.556V5.805c0-.875.205-1.222 1.192-1.222h3.085V0H8.761C4.915 0 3.208 1.451 3.208 4.23v3.103z'
      key='key-0'
    />
  ],
  viewBox
}

export default Object.assign(Image, {
  getDimensions,
  getDimensionsCss,
  defaultProps,
  displayName: 'IconSocialFacebook'
})
