import React from 'react'
import styled, { css } from 'styled-components'

const width = '14'
const height = '14'
const viewBox = '0 0 14 14'

const getDimensions = () => ({
  height,
  width
})

const getDimensionsCss = () => css`
  width: ${width}px;
  height: ${height}px;
`

const Image = styled.svg`
  ${({ noStyles }) => !noStyles ? getDimensionsCss() : null}
`

const defaultProps = {
  children: [
    <g
      fill='#262626'
      fillRule='evenodd'
      key='key-0'
    >
      <path
        d='M.314 12.536L12.413.436l1.273 1.273-12.1 12.1z'
      />
      <path
        d='M1.586.436l12.1 12.099-1.273 1.273-12.1-12.1z'
      />
    </g>
  ],
  viewBox
}

export default Object.assign(Image, {
  getDimensions,
  getDimensionsCss,
  defaultProps,
  displayName: 'BtnClose'
})
