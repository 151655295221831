import React from 'react'
import styled, { css } from 'styled-components'

const width = '32'
const height = '24'
const viewBox = '0 0 32 24'

const getDimensions = () => ({
  height,
  width
})

const getDimensionsCss = () => css`
  width: ${width}px;
  height: ${height}px;
`

const Image = styled.svg`
  ${({ noStyles }) => !noStyles ? getDimensionsCss() : null}
`

const defaultProps = {
  children: [
    <path
      fill='#1A1919'
      d='M26.153.245c-4.805-.327-15.508-.326-20.306 0C.65.6.039 3.74 0 12c.039 8.247.645 11.399 5.847 11.755 4.8.326 15.501.327 20.306 0C31.35 23.4 31.961 20.26 32 12 31.961 3.753 31.355.601 26.153.245zM12 17.333V6.667l10.667 5.324L12 17.333z'
      key='key-0'
    />
  ],
  viewBox
}

export default Object.assign(Image, {
  getDimensions,
  getDimensionsCss,
  defaultProps,
  displayName: 'IconSocialYoutube'
})
