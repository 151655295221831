import React from 'react'
import styled, { css } from 'styled-components'

const width = '12'
const height = '18'
const viewBox = '0 0 12 18'

const getDimensions = () => ({
  height,
  width
})

const getDimensionsCss = () => css`
  width: ${width}px;
  height: ${height}px;
`

const Image = styled.svg`
  ${({ noStyles }) => !noStyles ? getDimensionsCss() : null}
`

const defaultProps = {
  children: [
    <path
      fill='none'
      stroke='#BF1A18'
      strokeWidth='3'
      d='M1.391 1.328L9 8.938l-7.471 7.47'
      key='key-0'
    />
  ],
  viewBox
}

export default Object.assign(Image, {
  getDimensions,
  getDimensionsCss,
  defaultProps,
  displayName: 'BtnSubmit'
})
