import React from 'react'
import styled, { css } from 'styled-components'

const width = '24'
const height = '24'
const viewBox = '0 0 24 24'

const getDimensions = () => ({
  height,
  width
})

const getDimensionsCss = () => css`
  width: ${width}px;
  height: ${height}px;
`

const Image = styled.svg`
  ${({ noStyles }) => !noStyles ? getDimensionsCss() : null}
`

const defaultProps = {
  children: [
    <path
      d='M12 0c3.259 0 3.668.014 4.949.073 4.362.2 6.783 2.626 6.979 6.98C23.986 8.333 24 8.741 24 12c0 3.259-.014 3.668-.073 4.948-.197 4.362-2.625 6.78-6.979 6.98-1.28.058-1.689.072-4.948.072-3.259 0-3.667-.014-4.948-.072-4.362-.2-6.78-2.622-6.98-6.98C.014 15.668 0 15.259 0 12c0-3.259.014-3.667.073-4.948.2-4.362 2.622-6.78 6.98-6.98C8.333.014 8.741 0 12 0zm0 5.838a6.162 6.162 0 1 0 0 12.324 6.162 6.162 0 0 0 0-12.324zM12 16a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm6.406-11.845a1.44 1.44 0 1 0 0 2.881 1.44 1.44 0 0 0 0-2.881z'
      key='key-0'
    />
  ],
  viewBox
}

export default Object.assign(Image, {
  getDimensions,
  getDimensionsCss,
  defaultProps,
  displayName: 'IconSocialInstagram'
})
